/* For Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
  height: 10px; /* Set the width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2e2e6; /* Set the background color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4c4cf0; /* Set the background color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4c4cf0; /* Set the background color of the handle when hovered */
}
.card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.search-input-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
